import React, { useState } from "react";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";

export function FormFieldSelect(props) {
  const {
    fieldIndex,
    handleFieldValue,
    label,
    onChange,
    options,
    readOnly = false,
    value,
  } = props;
  let [items, setItems] = useState(null);

  function generateSelectOptions(formOptions, items) {
    // first, figure out how to sort the array
    //
    let hasLabels = true;
    let hasIndices = true;
    const opts = [];
    formOptions.forEach((fo, index) => {
      if (!fo.label) hasLabels = false;
      if (!fo.seq) hasIndices = false;
      opts.push(fo);
    });

    // then sort, accordingly
    //
    if (hasIndices) {
      opts.sort((a, b) => {
        return a.seq - b.seq;
      });
    } else if (hasLabels) {
      opts.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    } else {
      opts.sort((a, b) => {
        return a.fieldValue.localeCompare(b.fieldValue);
      });
    }

    // then, build the list of components
    //
    opts.forEach((fo, index) => {
      const label = hasLabels ? fo.label : fo.fieldValue;

      // Ive inserted this catch. Im not sure where fieldValue is coming from.
      // Working on this before actually working on forms ;)
      const value = fo.formOptionId ? fo.formOptionId : fo.fieldValue;

      items.push(<MenuItem key={value} value={value}>{label}</MenuItem>);
    });
  }

  const handleChange = (event) => {
    handleFieldValue(fieldIndex, event.target.value);
  };

  if (options) {
    if (!items) {
      items = [];
      generateSelectOptions(options, items);
      setItems(items);
    }
  } else items = [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputLabel htmlFor={label} sx={{ color: "black" }}>{label}</InputLabel> 
      </Grid>
      <Grid item xs={12} sx={{ pb: 2 }}>
        <Select
          disabled={readOnly}
          fullWidth
          id={label}
          labelId={label}
          onChange={onChange ? onChange : handleChange}
          value={value}
        >
          {items}
        </Select>
      </Grid>
    </Grid>
  );
}
