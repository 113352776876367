import { Button, useTheme } from "@mui/material";

export default function MaterialUiButton(props) {

  const theme = useTheme();
  const { palette = {} } = theme;
  const { error = {}, primary = {}, success = {}, typography = {} } = palette;

  const buttonStyles= {
    cancel: {
      color: "#FFFFFF !important",
      backgroundColor: `${primary?.main} !important`,
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: `${primary?.main} !important`,
      },
    },
    delete: {
      backgroundColor: `${error.main} !important`,
      color: "#FFFFFF !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: `${error.main} !important`,
      },
    },
    disabled: {
      backgroundColor: `${typography?.secondary} !important`,
      color: "#E6E7E8 !important",
      cursor: "not-allowed",
      "&:hover": {
        backgroundColor: `${typography?.secondary} !important`,
        color: "#E6E7E8 !important",
        cursor: "not-allowed",
      },
    },
    submit: {
      backgroundColor: `${success?.main} !important`,
      color: "#FFFFFF !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: `${success?.main} !important`,
      },
    },
  };

  const {
    color = "primary",
    classes = {},
    disabled = false,
    fullWidth = false,
    label = "Click Here",
    onClick = ()=>{},
    size = "small",
    sx = {},
    type = "button",
    variant = "contained",
  } = props;

  return (
    <Button
      classes={classes}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      sx={{
        ...(color === "cancel" ? buttonStyles.cancel : {}),
        ...(color === "delete" ? buttonStyles.delete : {}),
        ...(color === "submit" ? buttonStyles.submit : {}),
        ...(color === "primary" ? { backgroundColor: `${theme.main} !important` } : {}),
        ...(disabled ? buttonStyles.disabled : {}),
        // ...(className ? className : {}),
        ...sx,
      }}
      type={type}
      variant={variant}
    >
      {label}
    </Button>
  );
}
