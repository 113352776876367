import { atlasApi } from "../../services/atlas/atlasApi";
import { createSlice } from "@reduxjs/toolkit";
import { findAssetId } from "../../utils/urlUtils";

const initialState = {
  applicationTitle: "Logistics Pro Mobile",

  receivePanelAssetId: "",

  panelState: "start",
  hasStart: false,

  scanType: "",
  credentials: null,

  asset: null,
  location: null,
  manifest: null,
  BOL: null,

  actionLists: null,
  hasLists: false,

  hasAction: false,
  action: null,

  form: null,
  formFields: null,
};

export const selectApplicationTitle = (state) => state.panel.applicationTitle

export const selectReceivePanelAssetId = (state) => state.panel.receivePanelAssetId

export const selectPanelState = (state) => state.panel.panelState;
export const selectHasStart = (state) => state.panel.hasStart;

export const selectScanType = (state) => state.panel.scanType;
export const selectCredentials = (state) => state.panel.credentials;

export const selectAsset = (state) => state.panel.asset;
export const selectLocation = (state) => state.panel.location;
export const selectManifest = (state) => state.panel.manifest;
export const selectBOL = (state) => state.panel.BOL;

export const selectActionLists = (state) => state.panel.actionLists;
export const selectHasLists = (state) => state.panel.hasLists;

export const selectHasAction = (state) => state.panel.hasAction;
export const selectAction = (state) => state.panel.action;

export const selectForm = (state) => state.panel.form;
export const selectFormFields = (state) => state.panel.formFields;

export const selectOrganizationId = (state) => state.auth.organization.organizationId;

function credentialsReceived(state) {
  state.panelState = "credentials";
  state.hasAction = false;
  state.hasStart = false;

  state.scanType = "";
  state.credentials = null;

  state.asset = null;
  state.location = null;
  state.manifest = null;
  state.BOL = null;

  state.form = null;
  state.formFields = null;
  state.fieldComps = null;
}

function startReceived(state, st, v) {
  state.scanType = st;
  state.panelState = "action select";
  state.hasAction = false;
  state.hasStart = true;

  switch (st) {
    case "asset":
      state.asset = v.asset;
      break;
    case "location":
      state.location = v;
      break;
    case "manifest":
      state.manifest = v;
      break;
    case "BOL":
      state.BOL = v;
      break;
    default:
      break;
  }
}

export const panelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    addFormFieldValue(state, data) {
      const indexAndValueMap = data.payload;
      const field = state.formFields[indexAndValueMap.index];

      if (!field.fieldValues) field.fieldValues = [];

      if (indexAndValueMap.allowMultiple) {
        if (!field.fieldValues.includes(indexAndValueMap.value))
          field.fieldValues.push(indexAndValueMap.value);
      } else {
        if (field.fieldValues.length === 0)
          field.fieldValues.push(indexAndValueMap.value);
        else field.fieldValues[0] = indexAndValueMap.value;
      }
    },
    assignAction: (state, action) => {
      state.hasAction = true;
      state.action = action.payload;
      state.form = null;
      state.formFields = null;
      state.fieldComps = null;
      console.log("***** assign action");
    },
    assignAsset: (state, code) => {
      const c = code.payload;
      if (c && c.asset) startReceived(state, "asset", c);
    },
    assignAssetCreds: (state, code) => {
      const c = code.payload;
      if (c.length > 4) {
        const creds = findAssetId(c);
        if (creds && creds.assetId) {
          credentialsReceived(state);
          state.scanType = "asset";
          state.credentials = creds;
        }
      }
    },
    assignBOLCreds: (state, code) => {
      const c = code.payload;
      if (c.length > 4) startReceived(state, "BOL", c);
    },
    assignLocationCreds: (state, code) => {
      const c = code.payload;
      if (c.length > 4) startReceived(state, "location", c);
    },
    assignManifestCreds: (state, code) => {
      const c = code.payload;
      if (c.length > 4) startReceived(state, "manifest", c);
    },
    assignReceivePanelAssetId: (state,assetId)=>{
      state.receivePanelAssetId = assetId.payload
    },
    resetForm: (state) => {
      state.form = null;
      state.formFields = null;
      state.fieldComps = null;
    },
    resetPanel(state) {
      //      state = { ...initialState };
      console.log("***** resetPanel");
    },
    setActionLists: (state, listMap) => {
      state.hasLists = true;
      state.panelState = "form entry";
      state.actionLists = listMap.payload;
      state.form = null;
    },
    setApplicationTitle: (state,title) => {
      state.applicationTitle = title.payload
    },
    setAsset(state, a) {
      state.asset = a.payload;
    },
    setForm(state, f) {
      state.form = f.payload;
    },
    setFormFields(state, ff) {
      state.formFields = ff.payload;
    },
    setFormFieldValue(state, data) {
      const indexAndValueMap = data.payload;

      state.formFields[indexAndValueMap.index].fieldValue =
        indexAndValueMap.value;
    },
    setPanelState: (state, s) => {
      state.panelState = s.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      atlasApi.endpoints.assetScan.matchFulfilled,
      (state, { payload }) => {
        const c = payload;
        if (c && c.asset) startReceived(state, "asset", c);
      }
    );
  },
});

export const {
  addFormFieldValue,
  assignAction,
  assignAsset,
  assignAssetCreds,
  assignBOLCreds,
  assignLocationCreds,
  assignManifestCreds,
  assignReceivePanelAssetId,
  resetForm,
  resetPanel,
  setActionLists,
  setApplicationTitle,
  setAsset,
  setForm,
  setFormFields,
  setFormFieldValue,
  setPanelState,
} = panelSlice.actions;

export default panelSlice.reducer;
