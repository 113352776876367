import { useEffect } from "react";
import { Box, Card, Grid } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";

export default function ScanCard(props) {
  const { index = "", onRemove = () => {}, scan = {} } = props;
  const { submittedScan = {}, formFields } = scan;
  const { error = false, errors = [] } = submittedScan;

  const scanAsAnArray = Object.keys(formFields);
  const grabDeviceIndex =
    scanAsAnArray.filter((item) => formFields[item].fieldKey === "assetId") || [];
  const otherFields =
    scanAsAnArray.filter(
      (item) => formFields[item].fieldKey === "quantity.quantityCurrent" || formFields[item].fieldKey === "classificationMap" 
    ) || [];
  const device = formFields[grabDeviceIndex] || {};
  const { assetInfo = {} } = device;
  const { asset = {} } = assetInfo;
  const { tag = "" } = asset;

  return (
    <Card sx={{ margin: "1rem 0" }}>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px 8px 0px 8px",
        }}
      >
        <ClearIcon
          onClick={() => {
            onRemove(index);
          }}
        />
      </CardActions>
      <CardContent sx={{ padding: "0px 16px 16px 16px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Device:</Typography>{" "}
            <Typography variant="subtitle1">{tag}</Typography>
          </Grid>
          {otherFields.map((item, index) => {
            const selectedItem = formFields[item];
            const { label, fieldValue, selectionLabel  } = selectedItem;
            return (
              <Grid key={index} item xs={6}>
                <Typography variant="subtitle2">{label}:</Typography>
                <Typography variant="subtitle1">{selectionLabel ? selectionLabel : fieldValue}</Typography>
              </Grid>
            );
          })}

          {/* Error Message if applicable */}
          {error ? (
            <Grid item xs={12} sx={{color:'red'}}>
              <Typography variant="caption">Error:</Typography>
              <Typography variant="caption">{errors?.toString()}</Typography>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
}
