import { atlasApi } from "../services/atlas/atlasApi";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSettingsReducer from "../features/appSettings/appSettingsSlice";
import authReducer from "../features/auth/authSlice";
import cameraReducer from "../features/scanner/cameraSlice";
import panelReducer from "../features/panels/panelSlice";

const rootReducer = combineReducers({
  [atlasApi.reducerPath]: atlasApi.reducer,
  appSettings: appSettingsReducer,
  auth: authReducer,
  camera: cameraReducer,
  panel: panelReducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(atlasApi.middleware),
    preloadedState,
  });
};
