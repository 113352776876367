import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { validateDuoMFAuthentication } from "../../utils/api";
import MaterialSnackbar from "../../components/MaterialSnackbar";

export default function TwoFactorAuthPage(props) {
  const { apiUrl, logUserIn = () => {} } = props;
  const [state, setState] = useState({
    stateString: null,
    token: null,
  });
  const [modalState, setModalState] = useState({
    isError: false,
    showSnackbar: false,
    snackbarText: "",
  });
  const { isError, showSnackbar, snackbarText } = modalState;
  const { stateString, duoCode } = state;

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  // This useEffect grabs the state and duoCode from the URL
  useEffect(() => {
    setState({
      stateString: query.get("state"),
      duoCode: query.get("duo_code"),
    });
  }, [query]);

  // This useEffect fires off if we have a state and duoCode
  useEffect(() => {
    if (duoCode && stateString) {
      validateDuoMFAuthentication({ duoCode, stateString }).then((res = {}) => {
        const { success = false, token = "", user = {} } = res;
        if (success) {
          logUserIn({ user, token });
          window.location.href = "/";
        } else {
          setModalState({
            isError: true,
            showSnackbar: true,
            snackbarText: "Error validating two factor authentication",
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        }
      });
    } else if (!isError) {
      setModalState({
        isError: true,
        showSnackbar: true,
        snackbarText: "Error validating two factor authentication",
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    }
  }, [duoCode, isError, logUserIn, modalState, stateString]);

  return (
    <>
      <MaterialSnackbar
        handleClose={() =>
          setModalState((prevState) => ({ ...prevState, showSnackbar: false }))
        }
        open={showSnackbar}
        severity="error"
        text={snackbarText}
      />
    </>
  );
}
