const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const globalCSMToken = process.env.REACT_APP_CSM_TOKEN;

const getCsmToken = async (apiUrl, url) => {
  // REACT_APP_CSM_TOKEN is now the handshake token needed to get the CSM token
  const body = {
    csmToken: process.env.REACT_APP_CSM_HANDSHAKE_TOKEN,
    url: url,
  };
  const results = await fetch(`${apiUrl}data/csm/getCredential`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response.csmToken;
    })

    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });
  return results;
};

export const checkSession = async (token) => {
  let csmToken = "";

  // if prod, use secure CSM token method
  if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
    const origin = (process.env.REACT_APP_HOST || window.location.href)
      .split("/")
      .slice(0, 3)
      .join("/");

    const apiLabUrl = process.env.REACT_APP_API_LAB_ENDPOINT;

    csmToken = await getCsmToken(apiLabUrl, origin);
  } else {
    // else in dev / staging / testing, use csm token from environment variable
    csmToken = process.env.REACT_APP_CSM_TOKEN;
  }

  const results = await fetch(`${apiUrl}auth`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      csm_token: csmToken || "",
      userSessionId: token,
    }),
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });

  return results;
};

export const turnAssetIntoAContainerAsset = async (props) => {
  const { assetId, token } = props;
  const body = { assetMode: "Container", assetType: "Pallet" };

  const results = await fetch(`${apiUrl}assets/${assetId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });

  return results;
};

export const externalScanFromApp = async (props) => {
  const { assetMode = "Inventory", externalId, forceNewAsset = true, token } = props;
  const results = await fetch(
    `${apiUrl}assets/externalScanFromApp${forceNewAsset ? '?forceNewAsset=true' : ''}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({ assetMode, externalId, assetType: "Inventory" }),
    }
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((res) => {
      return res;
    });

  return results;
};

// At some point we can probably merge the submitEditAsset and this receiveFormSubmitEditAsset
// into one function. But due to a squeeze, im having to do this a little dirty.
export const receiveFormSubmitEditAsset = async (props) => {
  const {
    action,
    assetIdFromExternalScan,
    formAction,
    formFields = {},
    token,
  } = props;
  const { formId, label } = action;
  let assetId = "";
  let body = {
    action: "",
    propertiesMap: {
      formId,
      formData: [
        {
          fieldType: "associate",
          fieldValues: [assetIdFromExternalScan],
        },
      ],
    },
  };

  // Here we set the form action.
  if (formAction) {
    body.action = formAction;
  } else {
    body.action = label;
  }

  // Here we form the submit body - We traverse the formFields object and append the values
  Object.keys(formFields).forEach((field) => {
    const specificField = formFields[field];
    const { fieldKey, fieldValue } = specificField;

    if (fieldKey === "assetId") {
      assetId = fieldValue;
    } else {
      body.propertiesMap.formData.push(specificField);
    }
  });

  const results = await fetch(`${apiUrl}assets/${assetId}/action`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((res) => res);

  return results;
};

export const submitEditAsset = async (props) => {
  const {
    action,
    assetIdForHack = null,
    formAction,
    formFields = {},
    isHack = false,
    staticFormFields = null,
    token,
  } = props;
  const { formId, label } = action;
  let assetId = "";
  let body = {
    action: "",
    propertiesMap: {
      formId,
      formData: [],
    },
  };

  // Here we set the form action.
  if (formAction) {
    body.action = formAction;
  } else {
    body.action = label;
  }

  function appendFieldsToBody(fields = {}) {
    // Here we form the submit body - We traverse the formFields object and append the values
    Object.keys(fields).forEach((field) => {
      const specificField = fields[field];
      const { fieldKey, fieldType, fieldValue } = specificField;

      if (fieldKey === "assetId") {
        assetId = fieldValue;
      } else if (isHack) {
        // Here we are again, doing another hack thats now compounded on the one below. We need to verify how we want 
        //scannedAssets to function before getting rid of this hack and the one below. Hack if for "Creeate Container"
        body.propertiesMap.formData.push({
          fieldType: "scannedAssets",
          fieldKey: "scannedAssets",
          fieldValues: [assetIdForHack],
        });
      }
      else if (fieldType === "scannedAssets") {
        // This is a temporary fix. Need to come back and deal with the assetIdTextField to store this in an array.
        // Or create a new component.
        body.propertiesMap.formData.push({
          fieldType,
          fieldKey,
          fieldValues: [fieldValue],
        });
      } else {
        body.propertiesMap.formData.push(specificField);
      }
    });
  }

  if (staticFormFields) {
    appendFieldsToBody(staticFormFields);
  }

  appendFieldsToBody(formFields);

  // Here we form the submit body - We traverse the formFields object and append the values
  Object.keys(formFields).forEach((field) => {
    const specificField = formFields[field];
    const { fieldKey, fieldType, fieldValue } = specificField;

    if (fieldKey === "assetId") {
      assetId = fieldValue;
    } else if (fieldType === "scannedAssets") {
      // This is a temporary fix. Need to come back and deal with the assetIdTextField to store this in an array.
      // Or create a new component.
      body.propertiesMap.formData.push({
        fieldType,
        fieldKey,
        fieldValues: [fieldValue],
      });
    } else {
      body.propertiesMap.formData.push(specificField);
    }
  });

  const results = await fetch(`${apiUrl}assets/${assetId}/action`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });

  return results;
};

export const LoginUser = async (props) => {
  const { email, password } = props;

  const results = await fetch(`${apiUrl}auth/mobile/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      csmToken: globalCSMToken,
      email,
      password,
    }),
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });

  return results;
};

export const cancelScan = async (props) => {
  const { action, assetId = "", token } = props;
  const { formId } = action;
  let body = {
    assetId: null,
    action: "Cancel Scan",
    propertiesMap: {
      formId,
      formData: [
        {
          fieldType: "cancelScan",
          fieldKey: "assetId",
          fieldValue: assetId,
        },
      ],
    },
  };

  const results = await fetch(`${apiUrl}assets/${assetId}/action`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

export const getAsset = async (props) => {
  const { assetId = "", token } = props;

  const results = await fetch(`${apiUrl}assets/${assetId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

export const addAssemblyToAnAsset = async (props) => {
  const { action, assetId, assemblyId, token } = props;
  const { formId } = action;
  let body = {
    action: "assembly",
    propertiesMap: {
      formData: [
        {
          fieldKey: "assemblyId",
          fieldType: "text",
          fieldValue: assemblyId,
        },
      ],
      formId,
    },
  };

  if (assetId) {
    const results = await fetch(`${apiUrl}assets/${assetId}/action`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return {
          error: "Failed to update data, please contact system administrator.",
        };
      });
    return results;
  } else return null;
};

export const getTreeData = async (props) => {

  const { facilityId, itemNumber, organizationId, token } = props

  const elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              organization_id: organizationId,
            },
          },
          {
            term: {
              facility_id: facilityId,
            },
          },
          {
            nested: {
              path: "custom_data",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        "custom_data.key.keyword": "Item Number",
                      },
                    },
                    {
                      match: {
                        "custom_data.value.keyword": itemNumber,
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    },
    facilityId: facilityId,
    treeType: "zoneCountByFacility",
  };

  const response = await fetch(`${apiUrl}assets/createTree`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(elasticQuery),
  });
  const data = await response.json();
  return data;
};

export const searchAssets = async (props) => {
  const { token = "", organizationId = "", assetTag = "" } = props;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            regexp: {
              "tag.wildcard": {
                value: assetTag,
              },
            },
          },
          {
            terms: {
              asset_mode: ["Device"],
            },
          },
          {
            term: {
              organization_id: organizationId,
            },
          },
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: "batch_id",
                  },
                },
              ],
            },
          },
        ],
      },
    },
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

export async function generateDuoMFAuthenticationUrl(props) {
  const { apiUrl, username = {}, token } = props;

  const body = {
    csm: "mobile",
    username
  };

  const results = await fetch(
    `${apiUrl}auth/generateDuoMFAuthenticationUrl`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token
      },
      body: JSON.stringify(body),
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (!res.success) {
        return { error: res.error };
      }
      return res;
    })
    .catch((err) => {
      return { error: err };
    });
  return results;
}

export const validateDuoMFAuthentication = async (props) => {
  const { duoCode, stateString } = props;
  const body = {
    duoCode,
    state: stateString
  }

  const results = await fetch(`${apiUrl}auth/validateDuoMFAuthentication`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results
  // return { success: true }
}