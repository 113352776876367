import React, { useState } from "react";
import { openCamera, openCameraWithResult } from "./cameraSlice";
import { useDispatch } from "react-redux";

// Commenting out this library as it has not been updated for 3 years.
// And is not compatible with React 18. If this feature returns. We will need a new library.
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import MaterialUiButton from "../../components/Button/MaterialUiButton";

export default function CameraScan() {
  const [data, setData] = useState("Not Found");
  const dispatch = useDispatch();

  function handleClick(event) {
    event.preventDefault();
    dispatch(openCamera(false));
  }

  return (
    <div>
      {/* <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) {
            setData(result.text);
            dispatch(openCameraWithResult(result.text));
          } else setData("Not Found");
        }}
      /> */}
      <p>{data}</p>
      <MaterialUiButton
        fullWidth={true}
        label="Close Camera"
        onClick={(e) => {
          handleClick(e);
        }}
        sx={{ mt: 3, mb: 2 }}
        variant="contained"
      />
    </div>
  );
}
