import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getTreeData } from "../../../utils/api";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import AssetIdTextField from "../../../components/FormFields/AssetIdTextField/AssetIdTextField";
import MaterialUiButton from "../../../components/Button/MaterialUiButton";

function TreeMap(props) {
  const { token } = props;

  const [formFields, setFormFields] = useState({
    0: {
      fieldKey: "assetId",
      label: "AtlasLX Tag",
      fieldType: "text",
      fieldValue: "",
    },
  });
  const [state, setState] = useState({
    applyActiveTagChecked: false,
    assetIdUsedInHack: "",
    externalId: null,
    navigateToEventListPage: false,
    snackbarShow: false,
    snackbarText: "Success",
    snackbarSeverity: "success",
  });
  const [assetInfo, setAssetInfo] = useState({});
  const [treeData, setTreeData] = useState({});
  const [previousScans, setPreviousScans] = useState([]);
  //set currentslide to be index of last item in previousScans
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentItemNumber, setCurrentItemNumber] = useState("");

  const altRows = [];

  async function handleSubmit() {
    //get itemnumber, facilityid, and organizationid from asset info
    const itemNumber = assetInfo.asset.customMap["Item Number"];
    const facilityId = assetInfo.asset.facilityId;
    const organizationId = assetInfo.asset.organizationId;
    //make api call to get tree data
    const data = await getTreeData({
      facilityId,
      itemNumber,
      organizationId,
      token,
    });
    const totalCountInFacility = data.count;

    //set tree data
    const formattedData = addHierarchy(
      data.zoneCountByFacility.tree,
      [],
      totalCountInFacility
    );

    const formattedData2 = [];
    formattedData.forEach((item) => {
      if (item.type === "root") {
        const diff = totalCountInFacility - parseInt(item.value);
        item.value = `${totalCountInFacility}`;
        formattedData2.push(item);
        //only add un-zoned if there is a difference
        if (diff !== 0) {
          formattedData2.push({
            type: "branch",
            name: "un-zoned",
            value: `${diff}`,
            hierarchy: [...item.hierarchy, "un-zoned"],
          });
        }
      }
      //make sure only zones with item in them are shown
      if (item.type === "branch" && item.value !== "0") {
        formattedData2.push(item);
      }
      if (item.type === "leaf" && item.value !== "0") {
        formattedData2.push(item);
      }
    });

    const nextInLine = { itemNumber: itemNumber, itemTreeData: formattedData2 };
    //look through previousScans and if there is an object with the same itemNumber, remove it. Then add nextInLine to the end of the array
    const copyOfScans = [...previousScans];

    const queue = copyOfScans.filter(
      (item) => item.itemNumber !== nextInLine.itemNumber
    );
    queue.push(nextInLine);
    //if there are more than 10 items in the queue, remove the first item
    if (queue.length > 10) {
      queue.shift();
    }
    setPreviousScans(queue);
    //set currentslide to be index of last item in previousScans
    setCurrentSlide(queue.length - 1);

    setCurrentItemNumber(itemNumber);
    setTreeData(formattedData);
    setAssetInfo({});
    setFormFields((prevState) => ({
      ...prevState,
      [0]: { ...prevState[0], fieldValue: "" },
    }));
  }

  function addHierarchy(obj, hierarchy = [], totalCountInFacility) {
    if (Array.isArray(obj)) {
      obj.forEach((item) => addHierarchy(item, hierarchy));
    } else if (typeof obj === "object") {
      const newObj = { ...obj };
      // if obj.name is "missing", set obj.name to parent zone name + "un-binned"
      if (newObj.name === "missing") {
        newObj.name = "un-binned";
      }

      //if newObj.type is root and value is 0, set value to be totalCountInFacility
      if (newObj.type === "root" && newObj.value === "0") {
        newObj.value = totalCountInFacility;
      }

      if (newObj.name !== undefined) {
        newObj.hierarchy =
          hierarchy.length > 0 ? [...hierarchy, newObj.name] : [newObj.name];
      }

      for (const key in newObj) {
        if (newObj.hasOwnProperty(key) && typeof newObj[key] === "object") {
          addHierarchy(newObj[key], newObj.hierarchy || hierarchy);
        }
      }
      obj.hierarchy = newObj.hierarchy;
      altRows.push({ ...obj });
    }
    return altRows;
  }

  const treeColumns = [
    { field: "value", filterable: false, headerName: "Item Count", width: 200 },
  ];
  const fieldKey = "assetId";
  const index = 0;

  return (
    <div>
      <AssetIdTextField
        assetInfo={assetInfo}
        cypressId={"item-aggregation-txt-atlaslx-tag"}
        fieldIndex={index}
        formFields={formFields}
        key={index}
        label={"AtlasLX Tag"}
        onChange={(e) => {
          // We follow a seperate flow for forms with assemblyIds in the onSubmit
          if (fieldKey === "assemblyId") {
            setState((prevState) => ({
              ...prevState,
              externalId: e.target.value,
            }));
          }
          setFormFields((prevState) => ({
            ...prevState,
            [0]: {
              ...prevState[0],
              fieldValue: e.target.value,
            },
          }));
        }}
        onClear={() => {
          // Clear out the assetInfo
          setAssetInfo({});

          // Clear out any value this might of had
          setFormFields((prevState) => ({
            ...prevState,
            [0]: { ...prevState[0], fieldValue: "" },
          }));
        }}
        readOnly={false}
        setAssetInfo={setAssetInfo}
        token={token}
        value={formFields[0].fieldValue}
      />
      <Box
        backgroundColor="white"
        bottom="0"
        display="flex"
        justifyContent="space-between"
        position="sticky"
        width="100%"
      >
        {/* Submit Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="submit"
            disabled={
              assetInfo.success === undefined || assetInfo.success === false
                ? true
                : false
            }
            fullWidth={true}
            label="Submit"
            onClick={() => {
              handleSubmit();
            }}
            sx={{ mt: 3, mb: 2 }}
            variant="contained"
          />
        </Grid>

        {/* Clear Form Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            fullWidth={true}
            label="Clear"
            onClick={() => {
              setState({
                applyActiveTagChecked: false,
                externalId: null,
                navigateToEventListPage: false,
                snackbarShow: false,
              });

              // We need to clear all the formFieldValues
              setFormFields((prevState) => {
                let newState = { ...prevState };
                Object.keys(prevState).forEach((item) => {
                  const specificField = newState[item];

                  specificField.fieldValue = "";
                });

                return newState;
              });

              // Also need to clear out the assetInfo
              setAssetInfo({});
              //clear out currentItemNumber and treeData, reset currentSlide to 0
              setCurrentSlide(0);
              setCurrentItemNumber("");
              setTreeData({});
            }}
            sx={{ mt: 3, mb: 2 }}
            variant="contained"
          />
        </Grid>
      </Box>
      <Typography component={"h1"} variant="h6">
        Item Number: {previousScans[currentSlide]?.itemNumber}
      </Typography>
      <Box
        role="navigation"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ArrowBackIosOutlinedIcon
          aria-label="previous item"
          data-cypress-id={"item-aggregation-btn-previous"}
          disabled={currentSlide === 0 ? true : false}
          onClick={() => {
            //make sure current slide is not less than 0
            if (currentSlide > 0) {
              setCurrentSlide(currentSlide - 1);
            }
          }}
          role="button"
          sx={{
            color: "white",
            //if we're at the first slide, disable the button. Turn it grey and have cursor be not-allowed
            cursor: `${currentSlide === 0 ? "not-allowed" : "pointer"}`,
            backgroundColor: `${currentSlide === 0 ? "gray" : "#1976d2"}`,
            border: "1px solid #d9dedb",
            width: "50%",
          }}
          tabIndex={0}
        />
        <ArrowForwardIosOutlinedIcon
          aria-label="next item"
          data-cypress-id={"item-aggregation-btn-next"}
          disabled={
            currentSlide === previousScans.length - 1 ||
            previousScans.length === 0
              ? true
              : false
          }
          onClick={() => {
            //make sure current slide is not greater than the number of slides
            if (currentSlide < previousScans.length - 1) {
              setCurrentSlide(currentSlide + 1);
            }
          }}
          role="button"
          sx={{
            color: "white",
            cursor: `${
              currentSlide === previousScans.length - 1
                ? "not-allowed"
                : "pointer"
            }`,
            backgroundColor: `${
              currentSlide === previousScans.length - 1 ||
              previousScans.length === 0
                ? "gray"
                : "#1976d2"
            }`,
            border: "1px solid #d9dedb",
            width: "50%",
          }}
          tabIndex={0}
        />
      </Box>
      <DataGridPro
        //set row id to be concatenation of parent zone name and the row name
        columns={treeColumns}
        getRowId={(row) =>
          `${row.hierarchy[row.hierarchy.length - 2]} ${row.name}`
        }
        getTreeDataPath={(row) => row.hierarchy}
        rows={previousScans[currentSlide]?.itemTreeData || []}
        treeData
      />
      {/* //if there are no previous scans, render 0/0 */}
      <p>
        {previousScans.length === 0 ? 0 : currentSlide + 1} /{" "}
        {previousScans.length}
      </p>
    </div>
  );
}

export default TreeMap;
