import { selectHasCamera, assignHasCamera } from "./appSettingsSlice";
import { useAppSettingsQuery } from "../../services/atlas/atlasApi";
import { useSelector, useDispatch } from "react-redux";

export function AppSettings() {
  const dispatch = useDispatch();

  useAppSettingsQuery();

  let hasCamera = useSelector(selectHasCamera);
  if (hasCamera === null) {
    dispatch(assignHasCamera(false));
    hasCamera = false;
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          devices.forEach(function (device) {
            console.log(
              device.kind + ": " + device.label + " id = " + device.deviceId
            );
            if (device.kind === "videoinput") hasCamera = true;
          });
          console.log("hc 1 " + hasCamera);
          dispatch(assignHasCamera(hasCamera));
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
          console.log("hc 2 " + hasCamera);
          dispatch(assignHasCamera(hasCamera));
        });
    }
  }

  return <div>{/* <>{hasSettings? 'has settings' : 'no settings'}</> */}</div>;
}
