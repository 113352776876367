import { useEffect, useState } from "react";
import {
  selectAction,
  selectAsset,
  selectForm,
  selectLocation,
  setForm,
} from "../panelSlice";
import { Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import { selectForms } from "../../auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import MaterialSnackbar from "../../../components/MaterialSnackbar";
import SingleFormPanel from "../SingleFormPanel/SingleFormPanel";
import MultipleFormPanel2 from "../MultipleFormPanel/MultipleFormPanel2";

export function FormPanel(props) {
  const { token } = props;
  const [formFields, setFormFields] = useState({});
  const [state, setState] = useState({
    applyActiveTagChecked: false,
    assetIdUsedInHack: "",
    externalId: null,
    navigateToEventListPage: false,
    snackbarShow: false,
    snackbarText: "Success",
    snackbarSeverity: "success",
  });
  const {
    navigateToEventListPage,
    snackbarShow,
    snackbarText,
    snackbarSeverity,
  } = state;
  const action = useSelector(selectAction);
  const asset = useSelector(selectAsset);
  const binLocation = useSelector(selectLocation);
  const classificationMap = asset ? asset.classificationMap : null;
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const forms = useSelector(selectForms);
  const { name: formName = "", isMulti = true } = form || {};

  function handleSnackBar({ severity = "success", text = "Success" }) {
    // We need to throw the success modal up. Also need to null the externalId out
    setState((prevState) => ({
      ...prevState,
      snackbarSeverity: severity,
      snackbarShow: true,
      snackbarText: text,
    }));

    // We need to take the success modal down
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        snackbarShow: false,
      }));
    }, 3000);
  }

  // The useEffect grabs the selected forms fieldList and throws it into state
  useEffect(() => {
    if (action && forms && !form) {
      const formId = action.formId;

      // Grabbing the selected form
      const formTemp = forms.find((f) => f.formId === formId);
      // This statement sets up the formFields
      if (formTemp) {
        dispatch(setForm(formTemp));

        // Does the form have fields?
        if (formTemp.fields) {
          const collectionOfFields = {};

          formTemp.fields.forEach((field, index) => {
            const fieldCopy = { ...field };
            // We first check to see if the item is comign from a classification
            if (
              fieldCopy.fieldKey === "classificationMap" &&
              fieldCopy.topLevel &&
              classificationMap
            ) {
              const associatedClassification =
                asset.classificationMap[fieldCopy.topLevel];
              if (associatedClassification) {
                const fo = field.formOptions.find((fo) => {
                  return fo.label === associatedClassification;
                });

                if (fo) {
                  fieldCopy.fieldValue = fo.formOptionId;
                }
              }
            }

            // Setting the default value
            fieldCopy["fieldValue"] = "";

            collectionOfFields[index] = fieldCopy;
          });
          setFormFields(collectionOfFields);
        }
      }
    }
  }, [dispatch, asset, classificationMap, form, formFields, action, forms]);

  return (
    <Box
      component="div"
      sx={{
        alignItems: "center",
        padding: "10px",
      }}
    >
      {/* If no event is present, we are forcing them back to the eventList 
      page to avoid any accidental scans with the wrong event */}
      {action ? null : <Navigate to={"/eventList"} />}

      {/* Something weird was going on with adding this to the ternary above so i broke it out
      to its own line - Handles when the user clicks the  "Go back to event list" button */}
      {navigateToEventListPage ? <Navigate to={"/eventList"} /> : null}

      {/* Success Message Snackbar */}
      <MaterialSnackbar
        open={snackbarShow}
        handleClose={() =>
          setState((props) => ({ ...props, snackbarShow: false }))
        }
        text={snackbarText}
        severity={snackbarSeverity}
      />

      {isMulti ? (
        <MultipleFormPanel2
          action={action}
          asset={asset}
          binLocation={binLocation}
          classificationMap={classificationMap}
          dispatch={dispatch}
          form={form}
          formName={formName}
          forms={forms}
          handleSnackBar={handleSnackBar}
          setForm={setForm}
          setState={setState}
          snackbarShow={snackbarShow}
          token={token}
        />
      ) : (
        <SingleFormPanel
          action={action}
          asset={asset}
          binLocation={binLocation}
          classificationMap={classificationMap}
          dispatch={dispatch}
          form={form}
          formName={formName}
          forms={forms}
          handleSnackBar={handleSnackBar}
          setForm={setForm}
          token={token}
        />
      )}
    </Box>
  );
}
