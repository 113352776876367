import { useCallback, useEffect, useState } from "react";
import { extractAssetId, trimmedLink } from "../../../utils/extractAssetId";
import { externalScanFromApp, getAsset } from "../../../utils/api";
import { Grid, TextField, Typography } from "@mui/material";
import debounce from "lodash.debounce";

export default function ScannedAssetsTextField(props) {
  const {
    isHack = false,
    label,
    multiline = false,
    onChange,
    readOnly = false,
    setAssetIdUsedInHack = () => { },
    token,
    type = "text",
    value = "",
  } = props;
  const [tag, setTag] = useState(null);

  function extrapolateAssetId(event) {
    const eventString = event.target.value;
    // We steralize the link
    const steralizedLink = trimmedLink(eventString);

    // We then figure out if it is http or https
    const isHttp = steralizedLink.substring(0, 4) === "http";
    const isHttps = steralizedLink.substring(0, 5) === "https";

    if (isHttp || isHttps) {
      const assetId = extractAssetId(steralizedLink) || ""

      if (assetId.length > 0) {
        // Here in this hack we are throwing the assetId out into the parent.
        // That way when the submit fires off, we can fire off the function to 
        // turn this asset into a container asset. Then finish the rest of the form
        if (isHack) {
          setAssetIdUsedInHack(assetId);
        }

        getAsset({ assetId: assetId, token }).then((res) => {
          setTag(res.asset.tag);
          onChange({ target: { value: res.asset.assetId } });
        });
      }
    } else {
      externalScanFromApp({ forceNewAsset: false, externalId: eventString, token }).then((res = {}) => {
        const { assetId } = res.asset

        if (isHack) {
          setAssetIdUsedInHack(assetId);
        }

        setTag(res.asset.tag)
        onChange({ target: { value: assetId } });

      })
    }
  }

  const debouncedChangeHandler = useCallback(
    debounce(extrapolateAssetId, 750),
    []
  );

  useEffect(() => {
    //  This is to accomodate for when the user clicks "Clear" on the form
    if (value === "" && tag !== "") {
      setTag("");
    }
  }, [tag, value]);

  return (
    <Grid container>
      {/* Title */}
      <Grid item xs={12}>
        <Typography
          align="left"
          component="div"
          display="flex"
          fontSize="14px"
          variant="subtitle1"
        >
          {label}
        </Typography>
      </Grid>

      {/* Input */}
      <Grid item xs={12} sx={{ pb: 2 }}>
        {readOnly ? (
          <Typography variant="subtitle2">{value}</Typography>
        ) : (
          <TextField
            disabled={readOnly}
            id="scanned-asset"
            label=""
            inputProps={{ inputMode: "none" }}
            fullWidth
            multiline={multiline}
            onChange={(event) => {
              // extrapolateAssetId(event);
              onChange(event)
              debouncedChangeHandler(event);
            }}
            type={type}
            value={tag || value}
          />
        )}
      </Grid>
    </Grid>
  );
}
