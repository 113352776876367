import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { validate as uuidValidate } from "uuid";

//const kBaseDSEDevUrl = 'https://api-dev.locatorx.com/lx-atlas/api/';
const kAppName = "LXConnect";
const kBaseUrl = "https://api-ac4-dev.locatorx.com/lx-atlas/api/";
const kCsmToken = "ba0dad7d-532c-4282-826c-1d6449aa3ab3";

export const atlasApi = createApi({
  reducerPath: "atlasApi",
  baseQuery: fetchBaseQuery({
    baseUrl: kBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const auth = getState().auth;
      if (auth && auth.token) {
        headers.set("auth-token", auth.token);
      }
      return headers;
    },
  }),
  credentials: "include",
  endpoints: (builder) => ({
    appSettings: builder.query({
      query: () => ({
        url: "appSettings/mobile/" + kAppName,
        method: "POST",
        body: { csmToken: kCsmToken },
      }),
      transformResponse: (response) => response,
    }),
    auth: builder.query({
      query: (credentials) => ({
        url: "auth/mobile/",
        method: "POST",
        body: {
          csmToken: kCsmToken,
          email: credentials.email,
          password: credentials.password,
        },
      }),
    }),
    assetScan: builder.query({
      query: (credentials) => ({
        url: uuidValidate(credentials.assetId)
          ? "assets/" + credentials.assetId + "/scanFromApp"
          : "assets/externalScanFromApp",
        method: "POST",
        body: credentials,
      }),
    }),
    childAssetScan: builder.query({
      query: (credentials) => ({
        url: uuidValidate(credentials.assetId)
          ? "assets/" + credentials.assetId + "/scanFromApp"
          : "assets/externalScanFromApp",
        method: "POST",
        body: credentials,
      }),
    }),
    qAAssetScan: builder.query({
      query: (credentials) => ({
        url: "assets/quantityAssetScanFromApp",
        method: "POST",
        body: credentials,
      }),
    }),
    externalAssetScan: builder.query({
      query: (credentials) => ({
        url: "assets/externalScanFromApp",
        method: "POST",
        body: credentials,
      }),
    }),
    assetAction: builder.query({
      query: (credentials) => ({
        url: "assets/" + credentials.assetId + "/action",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useAppSettingsQuery,
  useAuthQuery,
  useAssetScanQuery,
  useChildAssetScanQuery,
  useExternalAssetScanQuery,
  useQAAssetScanQuery,
  useAssetActionQuery,
} = atlasApi;
