import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { openCamera, openCameraWithResult } from "../scanner/cameraSlice";
import { selectFormFields } from "./panelSlice";
import { selectHasCamera } from "../appSettings/appSettingsSlice";
import { useGeolocated } from "react-geolocated";
import { useQAAssetScanQuery } from "../../services/atlas/atlasApi";
import { useSelector, useDispatch } from "react-redux";
import MaterialUiButton from "../../components/Button/MaterialUiButton";

export function FormFieldQAAssets(props) {
  const dispatch = useDispatch();
  const { fieldIndex, handleFieldValue, label, buttonLabel, allowMultiple } =
    props;
  const hasCamera = useSelector(selectHasCamera);
  const formFields = useSelector(selectFormFields);

  const [skip, setSkip] = useState(true);
  const [body, setBody] = useState({});

  const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: { enableHighAccuracy: false },
      userDecisionTimeout: 5000,
    });

  function handleLocalFieldValue(barcode) {
    //        dispatch(setExternalBarcode(barcode));
    getPosition(); // trigger manual location fetch
    setBody({ assetId: barcode });
    setSkip(false);
  }

  function handleCameraResult(raw) {
    handleLocalFieldValue(raw);
    //        handleFieldValue(fieldIndex, raw);
    dispatch(openCamera(false));
  }

  function handleClick(event) {
    event.preventDefault();
    dispatch(openCameraWithResult(handleCameraResult));
  }

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const barcode = formData.get("barcode");

    if (barcode && barcode.length > 1) {
      handleLocalFieldValue(barcode);
      //            handleFieldValue(fieldIndex, barcode, allowMultiple);
    }
  }

  function CameraBits(props) {
    if (props.hasCamera) {
      return (
        <MaterialUiButton
          label={buttonLabel}
          onClick={(e) => {
            handleClick(e);
          }}
          sx={{ mt: 3, mb: 2 }}
          variant="contained"
        />
      );
    }
    return <div />;
  }

  const valueComps = [];
  if (formFields && formFields[fieldIndex]) {
    if (formFields[fieldIndex].fieldValues) {
      formFields[fieldIndex].fieldValues.forEach((v) => {
        valueComps.push(<Typography>{v}</Typography>);
      });
    } else if (formFields[fieldIndex].fieldValue) {
      valueComps.push(
        <Typography>{formFields[fieldIndex].fieldValue}</Typography>
      );
    }
  }

  // add the location
  //
  if (isGeolocationAvailable && isGeolocationEnabled && coords) {
    body.latitude = coords.latitude;
    body.longitude = coords.longitude;
    //        alert('position: '+coords.latitude+' '+coords.longitude);
  } else {
    body.latitude = 111;
    body.longitude = 222;
  }

  const postBody = { ...body };
  postBody.binLocation = props.binLocation;

  const { data, /* error, */ isLoading } = useQAAssetScanQuery(postBody, {
    skip,
  });
  console.log(
    "***** skip: " + skip + " isLoading: " + isLoading + " body: ",
    postBody
  );
  if (!skip && !isLoading) {
    setSkip(true);
    if (data) {
      console.log("***** have an external asset result: ", data);
      handleFieldValue(fieldIndex, data.asset.assetId, allowMultiple);
    } else console.log("***** NO asset result");
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          fontSize="14px"
          align="left"
          variant="subtitle1"
          component="div"
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="form"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          noValidate
          sx={{ pb: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                id="qa-assets-barcode"
                label="10-digit code"
                fullWidth
                name="barcode"
              />
            </Grid>
            <Grid item xs={3}>
              <MaterialUiButton
                fullWidth={true}
                label="Apply"
                type="submit"
                variant="contained"
              />
            </Grid>
          </Grid>
          <CameraBits hasCamera={hasCamera} />
          {valueComps}
        </Box>
      </Grid>
    </Grid>
  );
}
