import { forwardRef } from "react";
import {
  AppBar,
  Box,
  Dialog,
  Grid,
  Slide,
  Typography,
  Toolbar,
} from "@mui/material";
import ScanCard from "./ScanCard";
import { submitEditAsset } from "../../../utils/api";
import MaterialUiButton from "../../../components/Button/MaterialUiButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewScans(props) {
  const {
    action,
    handleSnackBar,
    open,
    scanCollection,
    setOpen,
    setScanCollection,
    snackbarShow,
    token,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const recursiveScan = () => {
    const sendEvents = async () => {
      const results = scanCollection.map(async (scan) => {
        scan.submittedScan = await submitEditAsset({
          action,
          formFields: scan.formFields,
          staticFormFields: scan.staticFormFields || null,
          token,
        });
        return scan;
      });
      return await Promise.all(results).then((completed) => {
        return completed;
      });
    };

    const results = sendEvents();

    return results;
  };

  function removeScan(scanIndex) {
    setScanCollection((prevState) => {
      const newState = [...prevState];
      newState.splice(scanIndex, 1);
      return newState;
    });
  }

  function clearSuccessfulScans(results) {
    // Making a deep copy of state. Stolen from: https://www.slingacademy.com/article/javascript-5-ways-to-create-a-new-array-from-an-old-array/
    const scanCollectionJson = JSON.stringify(scanCollection) || [];
    let newScanCollection = JSON.parse(scanCollectionJson) || [];
    let isError = false;

    // We need to remove the successful scans.
    results.forEach((scan) => {
      const { submittedScan = {} } = scan;
      const { asset = {} } = submittedScan;
      const { assetId = "" } = asset;
      const { success = false } = submittedScan;

      if (success) {
        // For each successful scan we traverse the newScansCollection and pop out the successful scan
        newScanCollection = newScanCollection.filter((element = {}) => {
          const { formFields = {} } = element;
          const elementAssetId = Object.values(formFields).find(
            (field) => field.fieldKey === "assetId"
          )?.fieldValue;

          return assetId !== elementAssetId;
        });
      } else {
        isError = true;
      }
    });

    // Set the new collection of scans
    setScanCollection(newScanCollection);

    // If there was an error on the scan, we keep them on the component so they can see which ones failed
    if (isError) {
      handleSnackBar({
        severity: "error",
        text: "Something went wrong with one of the scans",
      });
    } else {
      // We also need to close the dialog
      handleClose();

      // Also throw the snackbar up letting people know everything went well
      handleSnackBar({ severity: "success", text: "Success" });
    }
  }

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      {/* Title */}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h5">Scans</Typography>
        </Toolbar>
      </AppBar>

      {/* Close Button */}
      <Grid container sx={{ marginTop: "1.5rem", padding: "0 36px" }}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <MaterialUiButton
            fullWidth={true}
            label="Close Window"
            onClick={() => {
              handleClose();
            }}
            variant="text"
          />
        </Grid>
      </Grid>

      {/* Content and Submit Button */}
      <Grid container sx={{ height: "100%" }}>
        {/* Scanned Content */}
        <Box sx={{ padding: "0 2rem", width: "100%" }}>
          {scanCollection.map((scan, index) => {
            return (
              <ScanCard
                index={index}
                key={index}
                onRemove={removeScan}
                scan={scan}
              />
            );
          })}
        </Box>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box alignItems="end" display="flex" height="100%" padding="0 1rem">
            <div style={{ width: "100%" }}>
              <MaterialUiButton
                color="submit"
                fullWidth={true}
                label="Submit"
                onClick={() => {
                  recursiveScan().then((results) =>
                    clearSuccessfulScans(results)
                  );
                }}
                sx={{ marginBottom: "2rem" }}
                variant="contained"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
