import { Box, Grid, Typography } from "@mui/material";
import MaterialUiButton from "../../../components/Button/MaterialUiButton";
import { Link } from "react-router-dom";

export default function MainPanel(props) {
  return (
    <Box
      sx={{
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Grid container>
        {/* Intro */}
        <Grid item xs={12}>
          <Box mt={4}>
            <Typography variant="h5">
              Select an action from the available options to do whatever...
            </Typography>
          </Box>
        </Grid>

        {/* Event List */}
        <Grid item xs={12}>
          <Box mt={4}>
            <Link to="/eventList">
              <MaterialUiButton
                fullWidth
                label="EVENT LIST"
                size="large"
              />
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={4}>
            <MaterialUiButton fullWidth label="TRENDS" size="large" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
