// Previously we were using regex to pull the UUID out of the URL provided by the CQR.
// We used a 'positive look behind' which worked everywhere except chrome on iphones.
// Since we know what the starting string will look like, we can rely on the character positions

// Normally the string will come in as a https, but with our current client there is a
// possibility that the string will come in as a http, which is why we are accomodating for
// that instance

// An example link: https://lxs.locatorx.com/1fdf5e95-6c0c-4e96-9abf-26041e8d0c7a/?hash=1f544a

export const extractAssetId = (link) => {
  // We had an instance where a scanner added non ascii values to the scan. So we need to accomodate
  // We could write logic that checks whether or not the first character is an 'h' as in 'http(s)'
  // But im going to hold off on that for now. Assuming this works

  const trimmedLink = link
    // Get rid of any spaces
    .split(" ")
    .join("")
    // Get rid of any "
    .split('"')
    .join("")
    // Get rid of any '
    .split("'")
    .join("")
    // Get rid of any “
    .split("“")
    .join("")
    // Get rid of any ”
    .split("”")
    .join("")
    // Get rid of any «
    .split("«")
    .join("")
    // Get rid of any »
    .split("»")
    .join("")
    // Get rid of any „
    .split("„")
    .join("")
    // Get rid of any ‟
    .split("‟")
    .join("")
    // Get rid of any `
    .split("`")
    .join("")
    // Get rid of any ´
    .split("´")
    .join("")
    // Get rid of any ‘
    .split("‘")
    .join("")
    // Get rid of any ’
    .split("’")
    .join("")
    // Get rid of any ░
    .split("░")
    .join("")
  const isHttp = trimmedLink.substr(0, 4) === "http";
  const isHttps = trimmedLink.substr(0, 5) === "https";
  let assetId = null;

  if (isHttps) {
    assetId = trimmedLink.substr(25, 36);
  } else if (isHttp) {
    assetId = trimmedLink.substr(24, 36);
  }

  return assetId;
};

export const trimmedLink = (link) => {
  const trimmedLink = link
    // Get rid of any spaces
    .split(" ")
    .join("")
    // Get rid of any "
    .split('"')
    .join("")
    // Get rid of any '
    .split("'")
    .join("")
    // Get rid of any “
    .split("“")
    .join("")
    // Get rid of any ”
    .split("”")
    .join("")
    // Get rid of any «
    .split("«")
    .join("")
    // Get rid of any »
    .split("»")
    .join("")
    // Get rid of any „
    .split("„")
    .join("")
    // Get rid of any ‟
    .split("‟")
    .join("")
    // Get rid of any `
    .split("`")
    .join("")
    // Get rid of any ´
    .split("´")
    .join("")
    // Get rid of any ‘
    .split("‘")
    .join("")
    // Get rid of any ’
    .split("’")
    .join("")
    // Get rid of any ░
    .split("░")
    .join("")

  return trimmedLink
}